import React, { useEffect, useState } from 'react';
import usePages from 'src/customHooks/pages';
import { getUserInfo } from '../../../services/user.service';
import PinMedBookingIframe from 'src/components/PinMedBookingIframe';
import styled from 'styled-components';
import Loading from 'src/components/1TalkDesign/Loading';

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

export default function PinMedBooking() {
  const { token, lineProfilePhone } = usePages();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!token) return;
    getUserInfo()
      .then((user) => {
        if (
          user.patients &&
          user.patients.length !== 0 &&
          user.patients.find((u) => u.isSelf)
        ) {
          const self = user.patients.find((u) => u.isSelf);
          setUser({ ...self, ...user });
        } else {
          setUser(user);
        }
      })
      .catch((err) => {
        console.log('取得使用者資訊失敗', err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  return loading ? (
    <LoadingWrapper>
      <Loading color="rgb(0, 109, 177)" size={50} />
    </LoadingWrapper>
  ) : (
    <PinMedBookingIframe user={user} lineProfilePhone={lineProfilePhone} />
  );
}
